<!-- 首页商城 -->
<template>
  <div class="home">
    <van-search
      @change="reload"
      v-model="keyword"
      show-action
      placeholder="请输入搜索关键词"
      input-align="center"
    >
      <template #label>
        <div style="display: flex;align-items: center;height: 100%;" @click="goAddress">
          <span style="font-size: 12px;">{{city}}</span>
          <van-icon name="arrow-down" />
          <div style="height: 50%;margin-left: 5px;width: 1px;background-color: #E2E1E1;"></div>
        </div>
      </template>
      <template #action>
        <div style="display: flex;align-items: center;" @click="goMap()">
          <van-icon name="location-o" />
          <span>地图</span>
        </div>
      </template>
    </van-search>
    <!-- 轮播图 -->
    <div class="home-top">
      <van-swipe :autoplay="3000" class="home-swipe" :show-indicators="false">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image.image" />
        </van-swipe-item>
      </van-swipe>
      <div class="home-classify">
        <div
          class="home-classify-item"
          v-for="(i,ins) in middleImgs"
          :key="ins"
          @click="foodsList(i.id,i.name)"
        >
          <img :src="i.image" alt v-if="i.image" />
          <img src="../../../assets/shopping2.png" alt v-else />
          <span>{{i.name}}</span>
        </div>
      </div>
    </div>
    <div class="home-middle">
      <div class="home-activity"></div>
      <div class="home-coupon">
        <div class="coupon-title">
          <span class="coupon-title-left">优惠好券</span>
          <span class="coupon-title-right" @click="moreCoupon">更多推荐 ></span>
        </div>
        <div class="coupon-content">
          <div class="content-list" v-for="(item,ins) in coupon" :key="ins">
            <div class="content-title">满{{Math.round(item.consumer_full_money)}}元可用</div>
            <div class="content-moeny">
              <text>{{item.consumer_reduce_money}}</text>
              <img src="../../../assets/coupon/moeny.png" alt />
            </div>
            <div class="content-get" v-if="item.is_take == 1" @click="getTake(item)">立即领取</div>
            <div class="content-get1" v-else>立即领取</div>
          </div>
        </div>
      </div>
      <div class="home-recommend">
        <div class="home-recommend-top">
          <div class="recommend-business">
            <img
              class="recommend-info-head"
              src="https://p0.meituan.net/bbia/508e4acfbe20bcf8c00086854ba11d4047443.jpg@380w_214h_1e_1c"
            />
            <div class="recommend-business-info">
              <p class="recommend-business-name">THE ALLEY 鹿角巷（西单明珠店）</p>
              <div class="recommend-business-discount">
                <div class="discount-item">
                  <span>10减2</span>
                </div>
                <div class="discount-item">
                  <span>20减4</span>
                </div>
                <div class="discount-item">
                  <span>4元优惠券</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home-recommend-content">
          <p style="font-size: 17px;color: #333333;margin: 5px 0;font-weight: bold;">更多折扣，进店查看</p>
          <div class="goods-list">
            <div
              class="goods-item"
              v-for="(goods,ins) in goodsList.slice(0,3)"
              :key="ins"
              @click="indexDetails(goods.id)"
            >
              <img class="goods-image" :src="goods.image" alt />
              <p class="goods-name">{{goods.name}}</p>
              <div class="goods-price">
                <span class="goods-discountPrice">￥{{goods.price}}</span>
                <!-- <span class="goods-originalPrice">￥{{goods.originalPrice}}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="home-tab">
        <!-- <van-tabs v-model="active" sticky>
					<van-tab v-for="index in tabTitles" :title="index.name" style="width: 100%;">

						<div class="home-business">
							<div class="business-item">
								<img src="https://p1.meituan.net/bbia/4009682d827ef5a3a26885aa6f01728a127987.jpg"  class="business-head">
								<div class="business-content">
									<p class="business-name">Salud·欧洲冻酸奶（望京SOHO店）</p>
									<div class="business-info">
										<div class="business-info-left">
											<div>
												<img class="star" src="../../assets/star.png" ><span class="score">4.6</span>
												<span class="sell-number">月售122</span>
											</div>
											<span>起送￥20</span>
										</div>
										<span class="business-info-right">
											距您1.2km
										</span>
									</div>
									<div  class="business-goods-list">
										<div class="business-goods-item" v-for="goods in goodsList.slice(0,3)">
											<img class="business-goods-image" :src="goods.image" alt="">
											<p class="business-goods-name">{{goods.name}}</p>
											<div class="business-goods-price">
												<span class="business-goods-discountPrice">￥{{goods.discountPrice}}</span>
												<span class="business-goods-originalPrice">￥{{goods.originalPrice}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</van-tab>
				</van-tabs>
        -->
        <van-tabs v-model="active" sticky @click="toggle">
          <van-tab
            v-for="(item,index) in tabTitle"
            :key="index"
            :title="item.name"
            style="width: 100%;"
          >
            <waterFall :list="tabContentList" v-if="tabContentList.length>0"></waterFall>
          </van-tab>
          <van-tab
            v-for="(item,index) in tabTitles"
            :title="item.name"
            :name="item.id"
            style="width: 100%;"
            :key="index"
          >
            <waterFall :list="tabContentList" v-if="tabContentList.length>0"></waterFall>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import * as index from "@/api/index";
import waterFall from "../../../components/waterFall.vue";

import { reactive } from "vue";

import { toUrl } from "@/utils/tools";
import { Dialog, Toast } from "vant";

import { couponLst,couponTake } from "../../../api/coupon";
export default {
  components: {
    waterFall,
  },
  name: "Foods",
  data() {
    return {
      goodsList: [],
      tabContentList: [],
      tabTitle: [
        {
          name: "附近商家",
          id: 1,
        },
      ],
      tabTitles: [
        {
          name: "附近商家",
          id: 1,
        },
        {
          name: "冰爽一下",
          id: 2,
        },
        {
          name: "美妆服饰",
          id: 3,
        },
        {
          name: "数码家电",
          id: 4,
        },
      ],
      active: 0,
      showIndexBar: false,
      rateValue: 3,
      value: "",
      options: "",
      nullTip: "空空如也~",
      limit: 20, // 每页条数
      page: 1, // 页码
      type: 1,
      isDownLoading: false, // 下拉刷新
      isUpLoading: false, // 上拉加载
      upFinished: false, // 上拉加载完毕
      offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
      value2: 0,
      value1: 0,
      dataList: "",
      keyword: "",
      images: [],
      middleImgs: [],
      classifyImgs: [
        {
          img: require("../../../assets/homeClassifyImg1.png"),
          path: "/hotel",
        },
        {
          img: require("../../../assets/homeClassifyImg2.png"),
          path: "/shopping",
        },
        {
          img: require("../../../assets/homeClassifyImg3.png"),
          path: "/foods",
        },
      ],
      jingxuanList: [],
      coupon: [],
    };
  },
  computed: {
    city: function () {
      if (this.$store.state.app.city.length > 0) {
        return this.$store.state.app.city[0].name;
      } else {
        return this.$store.state.app.city.city;
      }
    },
    location: function () {
      return this.$store.state.app.latAndLng;
    },
  },
  mounted() {
    this.getSlideshow();
    this.getCate();
    this.getHotLst();
    this.getProductLst();
    this.couponLst();
  },
  methods: {
    couponLst() {
      let param = {
        type: "all",
        page: 1,
        limit: 4,
        is_hide: 1,
      };
      couponLst(param).then((res) => {
        console.log(res);
        this.coupon = res.data.list;
      });
    },
    getTake(item) {
      let param = {
        id: item.id,
      };
      couponTake(param).then((res) => {
        console.log(res);
        Toast(res.msg);
        this.couponLst();
      });
    },
    moreCoupon() {
      this.$router.push({
        path: "/h5/couponCentre",
        query: {
          type: 2,
        },
      });
    },
    goAddress() {
      this.$router.push({
        path: "/h5/address",
      });
    },
    goMap() {
      this.$router.push({
        path: "/h5/map",
      });
    },
    toggle(name) {
      console.log(name);
      this.active = name;
      // console.log(this.active)
      this.page = 1;
      this.getProductLst();
    },
    // 搜索
    reload() {
      this.getProductLst();
    },
    // 轮播图
    getSlideshow() {
      let params = {
        page: 2,
        position: 0,
      };
      index.getSlideshow(params).then((res) => {
        console.log(res);
        this.images = res.data;
        console.log(this.images);
      });
    },
    // 分类
    getCate() {
      let params = {
        type: 0,
      };
      index.getCate(params).then((res) => {
        console.log(res);
        this.middleImgs = res.data.data;
        this.tabTitles = res.data.data;
      });
    },
    // 分类详情跳转
    foodsList(id, name) {
      console.log(id);
      this.$router.push({
        path: "/h5/shoppinglist",
        query: {
          id: id,
          name: name,
        },
      });
    },
    // 店铺商品推荐
    getHotLst() {
      let params = {
        page: this.page,
        limit: this.limit,
        sort: "view",
        cate_type: 0,
        lng: this.location.longitude,
        lat: this.location.latitude,
      };
      index.getProductLst(params).then((res) => {
        console.log(res);
        this.goodsList = res.data.data;
        console.log(this.jingxuanList);
      });
    },
    indexDetails(id) {
      console.log(id);
      this.$router.push({
        path: "/h5/commDetail",
        query: {
          id: id,
        },
      });
    },
    // 商品列表
    getProductLst() {
      this.tabContentList = [];
      let params = {
        page: this.page,
        limit: this.limit,
        cid: this.active,
        lng: this.location.longitude,
        lat: this.location.latitude,
        keyword: this.keyword,
      };
      if (this.active == 0) {
        params.cate_type = 0;
        params.sort = "distance";
        params.cid = "";
      }
      index.getProductLst(params).then((res) => {
        console.log(res);
        this.tabContentList = this.tabContentList.concat(res.data.data);
        console.log(this.tabContentList);
      });
    },

    //获取列表
    getIndexList() {
      let params = {};
      params.page = this.page;
      params.limit = this.limit;
      params.org_id = this.value2;
      params.category_id = this.value1;
      params.keyword = this.keyword;
      console.log(params);
      index.getIndexList(params).then((res) => {
        const rows = res.data.list;

        if (rows.length === 0 || rows.length < this.limit) {
          // 加载结束
          this.upFinished = true;
          if (this.keyword) {
            this.dataList = res.data.list;
          } else {
            this.dataList = this.dataList.concat(rows);
          }

          return;
        } else {
          // 处理数据
          if (this.page === 1) {
            this.dataList = rows;
          } else {
            this.dataList = this.dataList.concat(rows);
          }
          this.upFinished = false;
        }

        this.$toast.clear();
      });
    },
    //获取分类
    getIndexCategory() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      let params = {};
      params.type = this.type;
      params.page = this.page;
      params.limit = this.limit;

      index.getIndexCategory().then((res) => {
        this.options = res.data;
      });
    },

    onDownRefresh() {},
    // 上拉加载请求方法
    onLoadList() {
      this.page++;
      this.getIndexList();
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/preset.less";

.home {
  // overflow-y: scroll;
  // height: calc(100% - 50px);
  padding-bottom: 60px;
  background-color: #f6f6f6;

  .home-swipe {
    width: 100%;
    height: 120px;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .home-top {
    padding: 0 15px;
    background-color: #ffffff;
    box-sizing: border-box;
    width: 100%;

    .home-classify {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      padding: 10px 0;
      box-sizing: border-box;

      .home-classify-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 38px;
          height: 38px;
        }

        span {
          font-size: 12px;
          color: #1a1a1a;
        }
      }

      img {
        width: 102px;
        height: 57px;
        border-radius: 5px;
      }
    }
  }

  .home-middle {
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;

    .home-activity {
      width: 98%;
      border-radius: 5px;
      height: 73px;
      background-image: url("https://m.360buyimg.com/wutong/jfs/t1/194419/11/17726/201796/6113ce2aEd8a377c2/0a77c848f2246ecf.jpg");
      background-size: cover;
      -webkit-background-size: cover;
      -o-background-size: cover;
      background-position: center 0;
      margin: 0 auto;
      margin-top: 10px;
    }

    .home-coupon {
      background-color: #ffffff;
      border-radius: 5px;
      margin-top: 10px;
      padding: 5px;
      .coupon-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;

        .coupon-title-left {
          font-size: 14px;
          font-weight: bold;
        }

        .coupon-title-right {
          font-size: 12px;
          color: #999999;
        }
      }
      //
      .coupon-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
        .content-list {
          background-image: url(../../../assets/coupon/indexC.png);
          background-size: 100% auto;
          width: 75px;
          height: 90px;
          // box-shadow: 0px 1px 1px 0px rgba(65, 63, 63, 0.38);
          display: flex;
          flex-direction: column;
          align-items: center;
          .content-title {
            font-size: 12px;
            font-weight: bold;
            color: #f84214;
            padding-top: 12px;
            transform: scale(calc(10 / 12));
          }
          .content-moeny {
            font-size: 35px;
            font-weight: bold;
            color: #fb421a;
            display: flex;
            img {
              width: 9px;
              height: 8px;
            }
          }
          .content-get {
            background-image: url(../../../assets/coupon/indexJ.png);
            background-size: 100% auto;
            width: 60px;
            height: 17px;
            font-size: 12px;
            text-align: center;
            color: #fb421a;
            transform: scale(calc(10 / 12));
          }
          .content-get1 {
            background: #cecece;
            width: 60px;
            height: 17px;
            font-size: 12px;
            border-radius: 10px;
            text-align: center;
            color: #fb421a;
            transform: scale(calc(10 / 12));
          }
        }
      }
    }

    .home-middle-swipe {
      height: 75px;
      border-radius: 5px;
      margin-top: 10px;

      ::v-deep .van-swipe__indicator {
        border-radius: 0;
        width: 5px;
        height: 3px;

        background-color: #ffffff;
      }

      ::v-deep .van-swipe__indicator--active {
        border-radius: 0;
        width: 10px;
        height: 3px;

        background-color: #ffffff;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-selected {
      background-color: #ffefcd;
      padding-bottom: 10px;
      width: 100%;
      border-radius: 10px;
      margin-top: 10px;

      .selected-title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 16px;
        position: relative;

        span {
          padding: 5px 10px;
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
        }

        .title-right {
          background-color: #fffcf5;
          margin: 0;
          padding: 5px 10px;
          width: 65%;
          position: absolute;
          right: 0;
          font-size: 10px;
          color: #5a4420;
          line-height: 20px;
          border-top-right-radius: 10px;
        }

        .title-right::after {
          content: " ";
          position: absolute;
          top: 0;
          left: -8px;
          border-width: 30px 8px;

          border-style: solid;

          border-color: #fffcf5 transparent transparent transparent;
        }
      }

      .selected-content {
        display: flex;
        width: 100%;
        margin-top: 5px;
        overflow-x: auto;
        justify-content: space-between;

        .selected-item {
          width: 28%;
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          margin-left: 5px;
          background-color: #ffffff;
          border-radius: 5px;
          padding: 10px;
          box-sizing: border-box;

          p {
            font-size: 14px;
            margin: 0;

            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }

          span {
            font-size: 12px;

            .item-price {
              font-size: 14px;
              color: #fc6228;
              font-weight: bold;
            }
          }

          img {
            width: 100%;
          }
        }
      }
    }

    .home-recommend {
      background-color: #ffffff;
      border-radius: 5px;
      margin-top: 10px;

      .home-recommend-content {
        padding: 5px 10px;

        .goods-list {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .goods-item {
            display: flex;
            flex-direction: column;
            width: 32%;

            .goods-image {
              width: 103px;
              height: 78px;
              border-radius: 5px;
            }

            .goods-name {
              font-size: 12px;
              color: #333333;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 5px 0;
            }

            .goods-price {
              display: flex;
              align-items: center;

              .goods-discountPrice {
                color: #fc6228;
                font-size: 12px;
              }

              .goods-originalPrice {
                color: #999999;
                font-size: 10px;
                margin-left: 5px;
                text-decoration: line-through;
              }
            }
          }
        }
      }

      .home-recommend-top {
        height: 67px;
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01a57Fki2A4kdVAht7I_!!6000000008150-0-tps-750-291.jpg_Q90.jpg");
        background-size: cover;
        -webkit-background-size: cover;
        -o-background-size: cover;
        background-position: center 0;

        .recommend-business {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: flex-end;
          padding-left: 15px;
          position: relative;
          top: 5px;
          z-index: 99;

          .recommend-info-head {
            width: 46px;
            height: 46px;
          }

          .recommend-business-info {
            display: flex;
            flex-direction: column;
            margin-left: 10px;

            .recommend-business-name {
              font-size: 15px;
              color: #ffffff;
              margin: 0;
            }

            .recommend-business-discount {
              display: flex;
              align-items: center;
              margin-top: 5px;

              .discount-item {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fc6228;
                font-size: 10px;
                color: #ffffff;
                margin-right: 5px;
              }
            }
          }
        }
      }

      .recommend-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;

        .recommend-title-left {
          font-size: 14px;
          font-weight: bold;
        }

        .recommend-title-right {
          font-size: 12px;
          color: #999999;
        }
      }

      .recommend-content {
        display: flex;
        width: 100%;
        margin-top: 5px;
        overflow-x: auto;
        justify-content: space-between;

        .recommend-item {
          width: 130px;
          position: relative;
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          margin-left: 5px;
          background-color: #ffffff;

          box-sizing: border-box;

          img {
            width: 100%;
            height: 85px;
            border-radius: 5px;
          }

          .recommend-item-rank {
            div {
              position: absolute;
              display: flex;
              flex-direction: column;
              width: 22px;
              height: 32px;
              top: 0;
              left: -2px;
              align-items: center;
              color: #ffffff;
            }

            .top {
              font-size: 8px;
              transform: scale(0.7);
            }

            .rank-numner {
              font-size: 12px;
            }

            img {
              position: absolute;
              left: -2px;
              top: -2px;
              width: 22px;
              height: 32px;
            }
          }

          .recommend-item-content {
            padding: 0 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .recommend-item-rate {
              display: flex;
              align-items: center;
              margin-bottom: 5px;
              box-sizing: border-box;
              background: #fff2ec;
              border-radius: 9px;
              color: #fc6228;
              opacity: 0.8;

              padding: 3px 5px;

              img {
                width: 15px;
                height: 15px;
              }

              span {
                font-size: 10px;
                color: #fb9a00;
              }
            }

            .recommend-item-title {
              font-size: 14px;
              color: #1a1a1a;
              margin: 6px 0 3px 0;
            }

            .recommend-item-comment {
              font-size: 10px;
              color: #999999;
            }
          }
        }
      }
    }

    .home-recommend-bottom {
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: space-between;

      .recommend-bottom-item {
        border-radius: 5px;
        background-color: #ffffff;
        width: 48%;

        .recommend-bottom-title {
          font-size: 14px;
          font-weight: bold;
          padding: 5px;
        }

        .recommend-bottom-content {
          display: flex;
          padding: 0 8px;
          justify-content: space-between;

          .recommend-bottom-content-item {
            position: relative;
            width: 48%;

            img {
              width: 73px;
              height: 73px;
            }
          }
        }

        .recommend-item-bottom-rank {
          background-color: #fc6228;
          position: absolute;
          top: 0;
          left: 0;
          width: 43px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 10px;
        }

        .recommend-item-bottom-title {
          font-size: 12px;
          color: #1a1a1a;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
          position: relative;
          top: -10px;
        }
      }
    }
  }

  .home-tab {
    height: 100%;
    width: 100%;

    // padding: 10px;
    .home-business {
      .business-item {
        display: flex;
        background-color: #ffffff;
        border-radius: 5px;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        justify-content: space-between;

        .business-head {
          width: 66px;
          height: 51px;
        }

        .business-content {
          width: calc(100% - 76px);

          .business-goods-list {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .business-goods-item {
              display: flex;
              flex-direction: column;
              width: 32%;

              .business-goods-image {
                width: 80px;
                height: 64px;
                border-radius: 5px;
              }

              .business-goods-name {
                font-size: 12px;
                color: #333333;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 5px 0;
              }

              .business-goods-price {
                display: flex;
                align-items: center;

                .business-goods-discountPrice {
                  color: #fc6228;
                  font-size: 12px;
                }

                .business-goods-originalPrice {
                  color: #999999;
                  font-size: 10px;
                  margin-left: 5px;
                  text-decoration: line-through;
                }
              }
            }
          }

          .business-name {
            font-size: 14px;
            color: #1a1a1a;
            margin: 0;
          }

          .business-info {
            display: flex;
            justify-content: space-between;
            margin-top: 6px;

            .business-info-left {
              display: flex;
              flex-direction: column;
              font-size: 10px;

              div {
                display: flex;
                align-items: center;
                margin-bottom: 6px;
              }

              .star {
                width: 15px;
                height: 15px;
              }

              .score {
                font-size: 13px;
                color: #fc6228;
                margin-right: 8px;
              }

              .sell-number {
                color: #999999;
              }
            }

            .business-info-right {
              font-size: 10px;
              color: #999999;
            }
          }
        }
      }
    }

    ::v-deep .van-tabs__nav {
      background-color: #f6f6f6;
    }

    ::v-deep .van-tabs__line {
      width: 20px;
      background-color: #fb6228;
      bottom: 22px;
    }

    .v-waterfall-content {
      width: 100%;
      height: 100%;
      position: relative;
      box-sizing: border-box;
    }

    .v-waterfall-item {
      position: absolute;
      border-radius: 10px;
      background-color: #f2270c;
    }

    .v-waterfall-item img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}
</style>
