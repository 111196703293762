<template>
	<div>
		<div class="waterfall_container" :style="{height: Math.max(...waterfallDeviationHeight)+50 + 'px'}" >
			<div v-for="(item, index) in waterfallList" :key="index" class="waterfall_item"
				@click="indexDetails(item)"
				:style="{ top: item.top + 'px', left: item.left + 'px', width: imgWidth + 'px', height: item.itemHeight }">

				<img class="water-img" :src="item.image" alt="" :style="{height:item.imgHeightPercent + '%'}">

				<div class="content" :style="{top: item.itemHeight }">
					<span class="title">{{item.name}}</span>
					<div class="info">
						<span class="price">￥{{item.price}}</span>
						<span class="sell">销量{{item.sales}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['list'],
		data() {
			return {
				waterfallList: [],
				imgWidth: 0, // 图片宽度
				contentHeight: 65, // 内容高度
				waterfallCol: 2, // 瀑布流列数
				itemRight: 0.1, // 图片右边距(以rem为单位)
				itemBottom: 0.1, // 图片下边距(以rem为单位)
				waterfallDeviationHeight: [], // 瀑布流高度偏移量
			};
		},
		mounted() {
			// 根元素像素
			const rootElePixel = parseInt(window.getComputedStyle(document.querySelector("html"), null).fontSize);
			this.itemRight *= rootElePixel;
			this.itemBottom *= rootElePixel;
			this.calculationValue();
			// this.imgPreloading()
		},
		methods: {
			// 附件推荐详情
			indexDetails(i) {
				// console.log(i)
				// if(i.cate_type == 3){
				// 	this.$router.push({
				// 		path: "/h5/hotelDetails",
				// 		query: {
				// 			id: i.id
				// 		}
				// 	})
					
				// }else{
					
				// }
				this.$router.push({
					path: "/h5/commDetail",
					query: {
						id: i.id
					}
				})
				
			},
			// 计算每项的宽度(即图片/内容宽度)
			calculationValue() {
				const containerWidth = document.body.clientWidth
				this.imgWidth = (containerWidth / this.waterfallCol) - this.itemRight - 10;
				// 初始化偏移高度数组,该数组用于存放每一列的高度
				this.waterfallDeviationHeight = new Array(this.waterfallCol);
				for (let i = 0; i < this.waterfallDeviationHeight.length; i++) {
					this.waterfallDeviationHeight[i] = 0;
				}
				this.imgPreloading(this.waterfallDeviationHeight);
				console.log(this.waterfallDeviationHeight)
			},
			// 图片预加载
			imgPreloading() {
				console.log(this.list)
				for (let i = 0; i < this.list.length; i++) {
					// console.log(this.list)
					const aImg = new Image();
					aImg.src = this.list[i].image;

					// 注意：图片加载完成的顺序不一样的，所以在页面显示图片的顺序每次都可能不一样
					aImg.onload = aImg.onerror = (e) => {
						const itemData = {};
						// 图片高度按比例缩放
						const imgHeight = (this.imgWidth / aImg.width) * aImg.height;
						// 获取图片高度比
						itemData.imgHeightPercent = (imgHeight / this.imgWidth) * 100;
						// 整体高度 = 图片高度 + 内容高度
						itemData.height = imgHeight + this.contentHeight;
						Object.assign(itemData, this.list[i])

						// 将每一项都push到一个列表中
						this.waterfallList.push(itemData);
						// 进行瀑布流布局
						this.waterfallFlowLayout(itemData);
					};
				}
				console.log(this.waterfallList)
			},
			// 瀑布流布局
			waterfallFlowLayout(itemData) {
				const shortestIndex = this.getShortestCol();
				itemData.top = this.waterfallDeviationHeight[shortestIndex];
				itemData.left = shortestIndex * (this.itemRight + this.imgWidth);
				this.waterfallDeviationHeight[shortestIndex] += itemData.height + this.itemBottom;
			},
			/**
			 * 找到最短的列并返回索引
			 * @returns {number} 索引
			 */
			getShortestCol() {
				const shortest = Math.min.apply(null, this.waterfallDeviationHeight);
				return this.waterfallDeviationHeight.indexOf(shortest);
			},
		},
	};
</script>

<style lang="less" scoped>
	.waterfall_container {
		width: 350px;

		box-sizing: border-box;
		position: relative;
		background-color: #F6F6F6;

		.waterfall_item {
			float: left;
			position: absolute;
			// 底边阴影
			background-color: #FFFFFF;
			box-shadow: 0px 2px 20px 0px rgba(61, 61, 61, 0.3);
			;
			border-radius: 5px;
			box-sizing: border-box;

			.water-img {
				width: 100%;
				height: 100%;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
			}

			.content {
				width: 100%;
				padding: 10px;
				box-sizing: border-box;
				// padding: 20px;
				// position: absolute;
				display: flex;
				flex-direction: column;

				.title {
					font-size: 15px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.info {
					display: flex;
					align-items: center;

					.price {
						font-size: 14px;
						color: #FC6228;
					}

					.sell {
						color: #999999;
						font-size: 10px;
						margin-left: 5px;
					}
				}
			}
		}

		img {
			vertical-align: middle;
		}
	}
</style>
